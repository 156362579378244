import schemeImg from 'pages/customProcess/components/unilever/assets/SVG- Strategy & BEM.png'
import { ProcessCard } from 'pages/customProcess/components/unilever/processPhases/processCard/ProcessCard'

const Strategy = () => {
  return (
    <ProcessCard
      type="solution"
      title="Strategy & BEM"
      description="Designing the Comms Strategy & Shape of the Plan"
      previousLink="/custom/unilever/insights"
      nextLink="/custom/unilever/media"
    >
      <img src={schemeImg} alt="" />
    </ProcessCard>
  )
}

export default Strategy
