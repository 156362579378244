import { MayBeNull } from '@wpp-open/core'
import { MouseEventHandler, useCallback, useMemo } from 'react'

import styles from 'pages/processBuilder/templates/circular2/Circular2Process.module.scss'
import { Group } from 'pages/processBuilder/templates/circular2/group/Group'
import { getPreviewImageUrl } from 'pages/processBuilder/utils'
import { ProcessFormConfig } from 'types/process/process'

export const Circular2Svg = ({
  activeGroup,
  activePhase,
  onGroupEnter,
  onGroupLeave,
  onPhaseEnter,
  onPhaseLeave,
  config,
}: {
  activeGroup: MayBeNull<number>
  activePhase: MayBeNull<number>
  onGroupEnter: (index: number) => MouseEventHandler
  onGroupLeave: MouseEventHandler
  onPhaseEnter: (index: number) => MouseEventHandler
  onPhaseLeave: MouseEventHandler
  config: ProcessFormConfig
}) => {
  const isActiveGroup = useCallback((index: number) => index === activeGroup, [activeGroup])

  const getGroupRotation = useCallback(
    (index: number) => {
      const isShiffted = config.groups.length > 2
      const rotateStep = 360 / config.groups.length

      return isShiffted ? rotateStep * index + rotateStep / 2 : rotateStep * index
    },
    [config.groups.length],
  )

  const groupSize = config.groups.length > 2 ? 360 / config.groups.length : 120
  const groupBgSize = useMemo(
    () => (2 * Math.PI * 323) / (config.groups.length > 2 ? config.groups.length : 3),
    [config.groups.length],
  )

  const widgetBg =
    (config.widgetBackgroundImage?.[0]
      ? `url(${getPreviewImageUrl(config.widgetBackgroundImage)})`
      : config.colors?.[1]) || '#A9BDFD'

  return (
    <svg
      width="1280"
      height="910"
      viewBox="0 0 1280 910"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={styles.svg}
    >
      <foreignObject
        width="604"
        height="604"
        x="338"
        y="153"
        className={styles.svgMainCircle}
        style={{
          background: widgetBg,
          borderColor: config.colors?.[2],
        }}
      />

      <circle cx="640" cy="455" r="238" fill={config.colors?.[3] || 'transparent'} />

      {config.groups.map((group, index) => (
        <Group
          group={group}
          phases={config.phases}
          isActive={isActiveGroup(index)}
          activePhase={activePhase}
          activeGroup={activeGroup}
          onGroupEnter={onGroupEnter(index)}
          onGroupLeave={onGroupLeave}
          onPhaseEnter={onPhaseEnter}
          onPhaseLeave={onPhaseLeave}
          rotation={getGroupRotation(index)}
          size={groupSize}
          key={index}
        />
      ))}

      <g opacity={Number.isInteger(activeGroup) || Number.isInteger(activePhase) ? '0.4' : '1'}>
        <circle
          cx="640"
          cy="455"
          r="238"
          stroke={config.colors?.[4] || '#001023'}
          className={styles.pointerEventsNone}
        />

        {config.groups.map((group, index) => (
          <circle
            cx="640"
            cy="217"
            r="9"
            fill={group?.colors?.[1] || '#001023'}
            key={index}
            transform={`rotate(${getGroupRotation(index)} 640 455)`}
            className={styles.pointerEventsNone}
          />
        ))}
      </g>

      <foreignObject
        width={192}
        height={192}
        x={544}
        y={359}
        className={styles.logoWrap}
        style={{ background: config?.colors?.[5], borderColor: config?.colors?.[6] }}
      >
        <img src={getPreviewImageUrl(config?.logo)} alt="" className={styles.logo} />
      </foreignObject>

      <defs>
        <pattern id="widgetPartBackgroundImage" width="1" height="1">
          <image
            x="0"
            y="0"
            width={groupBgSize}
            height="323"
            href={getPreviewImageUrl(config?.widgetPartBackgroundImage)}
            preserveAspectRatio="xMidYMid slice"
          />
        </pattern>
      </defs>
    </svg>
  )
}
