import { WppCheckbox, WppTypography } from '@platform-ui-kit/components-library-react'
import clsx from 'clsx'
import { ComponentPropsWithoutRef, forwardRef, useRef } from 'react'
import { mergeRefs } from 'react-merge-refs'

import { useCommonLabelProps } from 'components/common/utils'
import styles from 'components/form/formCheckbox/FormCheckbox.module.scss'
import { useField } from 'hooks/form/useField'
import { useProvideFieldFocus } from 'hooks/form/useProvideFieldFocus'

interface Props extends Omit<ComponentPropsWithoutRef<typeof WppCheckbox>, 'checked' | 'onChange' | 'onWppChange'> {
  name: string
  hint?: string
  className?: string
}

export const FormCheckbox = forwardRef<HTMLWppCheckboxElement, Props>(
  ({ name, hint, className, onWppBlur, labelConfig, labelTooltipConfig, ...rest }, ref) => {
    const {
      field: { ref: fieldRef, value, onChange, onBlur },
    } = useField({ name })

    const innerRef = useRef<HTMLWppCheckboxElement>(null)

    useProvideFieldFocus({
      fieldRef,
      setFocus: () => innerRef.current?.setFocus(),
    })

    const labelProps = useCommonLabelProps({ labelConfig, labelTooltipConfig })

    return (
      <div className={clsx(styles.root, className)}>
        <WppCheckbox
          {...rest}
          {...labelProps}
          ref={mergeRefs([innerRef, ref])}
          name={name}
          checked={value}
          onWppChange={event => onChange(event.detail.checked)}
          onWppBlur={e => {
            onBlur()
            onWppBlur?.(e)
          }}
        />
        {!!hint && (
          <WppTypography className={styles.hint} type="xs-body">
            {hint}
          </WppTypography>
        )}
      </div>
    )
  },
)
