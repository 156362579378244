import { CircularProcess1 } from 'pages/processBuilder/templates/circular1/CircularProcess1'
import { templateConfig as circular1Config } from 'pages/processBuilder/templates/circular1/templateConfig'
import { Circular2Process } from 'pages/processBuilder/templates/circular2/Circular2Process'
import { templateConfig as circular2Config } from 'pages/processBuilder/templates/circular2/templateConfig'
import { Templates } from 'types/process/process'

export const templates = {
  [Templates.Circular1]: CircularProcess1,
  [Templates.Circular2]: Circular2Process,
}

export const templateConfigs = {
  [Templates.Circular1]: circular1Config,
  [Templates.Circular2]: circular2Config,
}
