// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".SWgwS{cursor:pointer}", "",{"version":3,"sources":["webpack://./src/pages/processBuilder/templates/circular2/phasePoint/PhasePoint.module.scss"],"names":[],"mappings":"AAAA,OACE,cAAA","sourcesContent":[".isInteractive {\n  cursor: pointer;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"isInteractive": "SWgwS"
};
export default ___CSS_LOADER_EXPORT___;
