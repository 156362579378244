import schemeImg from 'pages/customProcess/components/unilever/assets/SVG- Insights development.png'
import { ProcessCard } from 'pages/customProcess/components/unilever/processPhases/processCard/ProcessCard'

const Insights = () => {
  return (
    <ProcessCard
      type="challenge"
      title="Insights development"
      description="Audience integration, design and data verification. Dimensionalising the context on Competitors, Category, Consumer, Culture"
      previousLink="/custom/unilever/interrogate"
      nextLink="/custom/unilever/strategy"
    >
      <img src={schemeImg} alt="" />
    </ProcessCard>
  )
}

export default Insights
