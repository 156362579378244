// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".mwxfT{position:relative;width:100%;max-width:398px;height:224px;max-height:224px;overflow:hidden}.mwxfT img{width:100%}", "",{"version":3,"sources":["webpack://./src/pages/customProcess/components/ikeaNew/components/phaseCard/PhaseCard.module.scss"],"names":[],"mappings":"AAAA,OACE,iBAAA,CACA,UAAA,CACA,eAAA,CACA,YAAA,CACA,gBAAA,CACA,eAAA,CAEA,WACE,UAAA","sourcesContent":[".phaseCard {\n  position: relative;\n  width: 100%;\n  max-width: 398px;\n  height: 224px;\n  max-height: 224px;\n  overflow: hidden;\n\n  img {\n    width: 100%;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"phaseCard": "mwxfT"
};
export default ___CSS_LOADER_EXPORT___;
