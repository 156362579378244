import { FileItemType } from '@platform-ui-kit/components-library'
import { MayBeNull, AttachmentMetadataResponse } from '@wpp-open/core'
import { useOs } from '@wpp-open/react'
import * as zod from 'zod'

import { useUploadProcessFilesApi } from 'hooks/attachments/useUploadProcessFilesApi'
import { templateConfigs } from 'pages/processBuilder/templates'
import { Process, Templates, ProcessFormConfig, FormPhase, MapPayload, Phase } from 'types/process/process'
import { isFile } from 'utils/files'

export const getInitialValues = (data: MayBeNull<Process>): ProcessFormConfig => {
  const getFieldValue = ({ name, size, url }: AttachmentMetadataResponse) => ({ name, size, url: url || '', type: '' })

  return {
    template: data?.template || Templates.Circular1,
    logo: (data?.logo ? [getFieldValue(data?.logo)] : []) as [FileItemType?],
    background: (data?.background ? [getFieldValue(data?.background)] : []) as [FileItemType?],
    widgetBackgroundImage: (data?.widgetBackgroundImage ? [getFieldValue(data?.widgetBackgroundImage)] : []) as [
      FileItemType?,
    ],
    widgetPartBackgroundImage: (data?.widgetPartBackgroundImage
      ? [getFieldValue(data?.widgetPartBackgroundImage)]
      : []) as [FileItemType?],
    colors: data?.colors || [],
    button: {
      title: data?.button?.title,
      url: data?.button?.url,
      colors: data?.button?.colors || [],
      isVisible: data?.button?.isVisible,
    },
    phases:
      data?.phases
        .map(({ overlayDescriptions, ...phase }) => ({
          ...phase,
          overlayDescriptions: overlayDescriptions.map((label, id) => ({ label, id })),
          localId: phase.id,
        }))
        .sort(({ order: orderA }, { order: orderB }) => (orderA && orderB ? orderA - orderB : 0)) || [],
    groups:
      data?.groups
        .map(({ phaseOrders, ...group }) => ({
          ...group,
          phasesLocalIds: getPhaseId(phaseOrders, data?.phases),
        }))
        .sort(({ order: orderA }, { order: orderB }) => (orderA && orderB ? orderA - orderB : 0)) || [],
  }
}

export const useMapFormValuesToPayload = () => {
  const handleUploadProcessFiles = useUploadProcessFilesApi()
  const {
    osContext: { tenant },
  } = useOs()

  return async <T extends boolean>(
    {
      logo,
      background,
      widgetBackgroundImage,
      widgetPartBackgroundImage,
      phases,
      groups,
      ...values
    }: ProcessFormConfig,
    _isEdit: T,
  ): Promise<MapPayload<T>> => {
    const imagesMetadata = Object.fromEntries(
      await Promise.all(
        Object.entries({ logo, background, widgetBackgroundImage, widgetPartBackgroundImage })
          .filter(([_, [value]]) => !value || isFile(value))
          .map(async ([key, [value]]) => [key, value ? (await handleUploadProcessFiles([value as File]))[0] : null]),
      ),
    )

    return {
      ...values,
      tenantId: tenant.id,
      logo: imagesMetadata.logo,
      background: imagesMetadata.background,
      widgetBackgroundImage: imagesMetadata.widgetBackgroundImage,
      widgetPartBackgroundImage: imagesMetadata.widgetPartBackgroundImage,
      phases: phases.map(({ localId, overlayDescriptions, ...phase }, index) => ({
        ...phase,
        overlayDescriptions: overlayDescriptions.map(({ label }) => label),
        order: index + 1,
      })),
      groups: groups.map(({ phasesLocalIds, ...group }, index) => ({
        ...group,
        phaseOrders: getPhaseOrder(phasesLocalIds, phases),
        order: index + 1,
      })),
    } as MapPayload<T>
  }
}

const getPhaseOrder = (phasesLocalIds: string[], phases: FormPhase[]) => {
  return phasesLocalIds?.reduce((accum: number[], id: string) => {
    const index = phases.findIndex(({ localId }) => localId === id)
    index > -1 && accum.push(index + 1)

    return accum
  }, [])
}

const getPhaseId = (phasesOrders: number[], phases: Phase[]) => {
  return phasesOrders?.reduce((accum: string[], order: number) => {
    const phase = phases.find(({ order: itemOrder }) => itemOrder === order)
    phase && accum.push(phase.id)

    return accum
  }, [])
}

export const getPreviewImageUrl = (value: [FileItemType?]) =>
  isFile(value?.[0]) ? URL.createObjectURL(value?.[0]) : value?.[0]?.url

export const processSchema = zod.object({
  template: zod.string().min(1),
  logo: zod.array(zod.any()).max(1),
  background: zod.array(zod.any()).max(1),
  widgetBackgroundImage: zod.array(zod.any()).max(1),
  widgetPartBackgroundImage: zod.array(zod.any()).max(1),
  colors: zod.array(zod.string()),
  button: zod.object({
    title: zod.string().optional().nullable(),
    url: zod.string().optional().nullable(),
    colors: zod.array(zod.string()).optional(),
    isVisible: zod.boolean().optional(),
  }),
  phases: zod.array(
    zod.object({
      title: zod.string().optional().nullable(),
      overlayDescriptions: zod.array(zod.object({ label: zod.string(), id: zod.number() })).optional(),
      description: zod.string().optional().nullable(),
      groupTitle: zod.string().optional().nullable(),
      colors: zod.array(zod.string()).optional(),
      url: zod.string().optional().nullable(),
      innerPageEnabled: zod.boolean().optional().nullable(),
      id: zod.string().optional().nullable(),
      localId: zod.string().optional().nullable(),
    }),
  ),
  groups: zod.array(
    zod.object({
      title: zod.string().optional().nullable(),
      description: zod.string().optional().nullable(),
      colors: zod.array(zod.string()).optional(),
      phasesLocalIds: zod.array(zod.string()).optional(),
      id: zod.string().optional().nullable(),
    }),
  ),
})

export const getIsFieldHidden = (template: Templates) => {
  const config = templateConfigs[template].fields as any

  return (fieldName: string, subfieldName?: string) =>
    !subfieldName ? !config?.[fieldName] : !config?.[fieldName][subfieldName]
}
