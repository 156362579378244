import { WppButton, WppIconGear } from '@platform-ui-kit/components-library-react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router'

import { BaseErrorState } from 'components/baseErrorState/BaseErrorState'
import { SvgNoRecentWork } from 'components/svg/SvgNoRecentWork'
import styles from 'pages/processBuilder/emptyState/EmptyState.module.scss'
import { routesManager } from 'utils/routesManager'

export const EmptyState = ({ isAdmin, onConfigureClick }: { isAdmin: boolean; onConfigureClick: () => void }) => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  if (!isAdmin) navigate(routesManager.notFound.url)

  return (
    <BaseErrorState
      illustration={<SvgNoRecentWork className={styles.img} />}
      mainTitle={t('process.empty_state.title')}
      subTitle={t('process.empty_state.description')}
      className={styles.root}
      actions={
        <WppButton onClick={onConfigureClick}>
          {t('process.empty_state.button_text')} <WppIconGear slot="icon-start" />
        </WppButton>
      }
    />
  )
}
