import schemeImg from 'pages/customProcess/components/unilever/assets/SVG- Consumer experience.png'
import { ProcessCard } from 'pages/customProcess/components/unilever/processPhases/processCard/ProcessCard'

const Consumer = () => {
  return (
    <ProcessCard
      type="delivery"
      title="Consumer experience"
      description="Developing the plan architecture across the funnel or POE"
      previousLink="/custom/unilever/media"
      nextLink="/custom/unilever/measurement"
    >
      <img src={schemeImg} alt="" />
    </ProcessCard>
  )
}

export default Consumer
