import { Route, Routes } from 'react-router-dom'

import Consumer from 'pages/customProcess/components/unilever/processPhases/pages/consumer/Consumer'
import Insights from 'pages/customProcess/components/unilever/processPhases/pages/insights/Insights'
import Interrogate from 'pages/customProcess/components/unilever/processPhases/pages/interrogate/Interrogate'
import Learning from 'pages/customProcess/components/unilever/processPhases/pages/learning/Learning'
import Measurement from 'pages/customProcess/components/unilever/processPhases/pages/measurement/Measurement'
import Media from 'pages/customProcess/components/unilever/processPhases/pages/media/Media'
import Strategy from 'pages/customProcess/components/unilever/processPhases/pages/strategy/Strategy'

export const UnileverRoutes = () => {
  return (
    <Routes>
      <Route path="interrogate" element={<Interrogate />} />
      <Route path="insights" element={<Insights />} />
      <Route path="strategy" element={<Strategy />} />
      <Route path="media" element={<Media />} />
      <Route path="consumer" element={<Consumer />} />
      <Route path="measurement" element={<Measurement />} />
      <Route path="learning" element={<Learning />} />
    </Routes>
  )
}
